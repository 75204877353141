/**
 * @typedef {{label: any, value: string}} ColumnDefinitionType
 */

/**
 * @typedef {function(import("i18next").t, string): ColumnDefinitionType[]} ColumnDefinitionFunctionType
 */

/**
 * @type {ColumnDefinitionFunctionType}
*/
const columnLevel = (t, gmt) => [
  {
    label: t('date_gmt', { gmt }),
    value: 'dateTime'
  },
  {
    label: t('reference'),
    value: 'device_reference'
  },
  {
    label: t('export_form_silo_name_placeholder'),
    value: 'device_name'
  },
  {
    label: t('level_percent'),
    value: 'level_percent'
  },
  {
    label: t('string_workspace_filling_unit', { value: t('level_t') }),
    value: 'level_t'
  },
  {
    label: t('string_workspace_filling_unit', { value: t('free_space') }),
    value: 'missingWeight'
  }
]

export { columnLevel }
