import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import NanoDialog from '../../../shared/components/NanoDialog'
import { Alert, Box, DialogContent, DialogContentText, ImageList, ImageListItem, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { InfoIcon } from '../../../shared/icons/index'
import LoadingPage from '../../../shared/components/LoadingPage'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  media: PropTypes.array,
  isLoading: PropTypes.bool

}

const defaultProps = { onClose: () => {}, media: [], isLoading: false }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const MediaDialog = ({ open, onClose, media, isLoading }) => {
  const [focusIndex, setFocusIndex] = useState(undefined)
  const dialogRef = useRef(null)
  const { t } = useTranslation()
  const theme = useTheme()
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (dialogRef.current) {
        if (event.key === 'ArrowRight') {
          setFocusIndex((prevIndex) => (prevIndex + 1) % media.length)
        } else if (event.key === 'ArrowLeft') {
          setFocusIndex((prevIndex) => (prevIndex - 1 + media.length) % media.length)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [media.length])

  const openImage = (index) => {
    setFocusIndex(index)
  }
  return (
    <>
      <NanoDialog title={t('media')} open={open} onClose={onClose}>
        {isLoading
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <LoadingPage withLogo={false} />
            </Box>
            )
          : (
            <>
              <DialogContentText>{t('media_explanation')}</DialogContentText>
              {media.length === 0
                ? (
                  <Alert
                    sx={{
                      bgcolor: theme.palette.warning.light,
                      alignItems: 'center',
                      button: { whiteSpace: 'nowrap', textAlign: 'center' }
                    }}
                    icon={<InfoIcon color={theme.palette.warning.main} height={24} width={24} />}
                  >
                    <Typography variant='body1' color='text.secondary'>
                      <Trans i18nKey='no_media_explanation' components={{ u: <u /> }} />
                    </Typography>
                  </Alert>
                  )
                : (
                  <ImageList variant='quilted' cols={media.length <= 5 ? media.length : 5} gap={8} rowHeight='auto'>
                    {media?.map((m, index) => (
                      <ImageListItem key={m.idMedia}>
                        <img
                          key={m.idMedia}
                          src={m.url}
                          alt={m.key}
                          loading='lazy'
                          style={{ cursor: 'pointer' }}
                          onClick={() => openImage(index)}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                  )}
            </>
            )}
      </NanoDialog>

      <NanoDialog title='' open={focusIndex !== undefined} onClose={() => setFocusIndex(undefined)}>
        <DialogContent ref={dialogRef}>
          {!!media[focusIndex] && (
            <img
              src={media[focusIndex].url}
              alt={media[focusIndex].key}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </DialogContent>
      </NanoDialog>
    </>
  )
}

MediaDialog.propTypes = propTypes
MediaDialog.defaultProps = defaultProps
// #endregion

export default MediaDialog
