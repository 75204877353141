import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle, Box, Button, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { CallIcon, InfoIcon } from '../../../shared/icons'
import { useNavigate } from 'react-router-dom'
import { getOperationSuggestedExplanation, getOperationSuggestedIcon, getOperationSuggestedTitle } from '../../../shared/components/OperationSuggestedInfo'
import { isKnownWarningCause } from '../../../shared/models/operationSuggested'
import { getWarningCauseExplanation, getWarningCauseIcon, getWarningCauseTitle } from '../../../shared/components/WarningCauseInfo'

const getExplanations = (t, theme, metadata, status, installDate) => {
  if (status === 'problem') {
    return {
      title: getOperationSuggestedTitle(t, metadata?.operation_suggested),
      explanation: getOperationSuggestedExplanation(t, metadata?.operation_suggested),
      Icon: getOperationSuggestedIcon(metadata?.operation_suggested),
      color: theme.palette[status]
    }
  }
  if (status === 'pending') {
    if (metadata?.info_to_calibrate === 'missing_delivery') {
      return {
        title: t('device_status_pending_missing_delivery_title'),
        explanation: <Trans i18nKey='device_status_pending_missing_delivery_explanation' components={{ u: <u /> }} />,
        Icon: InfoIcon,
        color: theme.palette[status] ?? theme.palette.info
      }
    }
    if (metadata?.info_to_calibrate === 'missing_level') {
      return {
        title: t('device_status_pending_missing_level_title'),
        explanation: <Trans i18nKey='device_status_pending_missing_level_explanation' components={{ u: <u /> }} />,
        Icon: InfoIcon,
        color: theme.palette[status] ?? theme.palette.info
      }
    }
    if (metadata?.info_to_calibrate === 'missing_level_delivery') {
      return {
        title: t('device_status_pending_missing_level_delivery_title'),
        explanation: <Trans i18nKey='device_status_pending_missing_level_delivery_explanation' components={{ u: <u /> }} />,
        Icon: InfoIcon,
        color: theme.palette[status] ?? theme.palette.info
      }
    }
    // return an explanation if the install date is before 6 days ago
    const sixDaysAgo = new Date(new Date().setDate(new Date().getDate() - 6))

    if (!metadata.info_to_calibrate && installDate && new Date(installDate) >= sixDaysAgo) {
      return {
        title: t('device_status_pending_before_6_days_title'),
        explanation: <Trans i18nKey='device_status_pending_before_6_days_explanations' components={{ u: <u /> }} />,
        Icon: InfoIcon,
        color: theme.palette[status] ?? theme.palette.info
      }
    }
  }
  // case for pending or ok with warning cause
  if (isKnownWarningCause(metadata?.warning_cause)) {
    return {
      title: getWarningCauseTitle(t, metadata?.warning_cause),
      explanation: getWarningCauseExplanation(t, metadata?.warning_cause),
      Icon: getWarningCauseIcon(metadata?.warning_cause),
      color: theme.palette.warning
    }
  }
  return {
    title: null,
    explanation: null,
    Icon: null,
    color: theme.palette[status] ?? theme.palette.info
  }
}

const propTypes = {
  data: PropTypes.shape({}),
  loading: PropTypes.bool
}

const defaultProps = { data: {}, loading: true }

function Explanations ({ data, loading }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { title, Icon, color, explanation } = getExplanations(t, theme, data.metadata, data.status, data?.device_install_date)
  if (!loading) {
    return title
      ? (
        <Alert
          action={data.status === 'problem' &&
            <Box alignSelf='center'>
              <Button variant='contained' color='error' startIcon={<CallIcon color={theme.palette.problem.contrastText} />} onClick={() => navigate('/user-help')}>
                {t('button_support')}
              </Button>
            </Box>}
          sx={{
            bgcolor: color.light,
            // struggled to prevent button alert from shrinking. solution from https://github.com/mui/material-ui/issues/39392#issuecomment-1759285935
            button: { whiteSpace: 'nowrap', textAlign: 'center' }
          }}
          icon={<Icon color={color.main} height={24} width={24} />}
        >
          <AlertTitle sx={{ color: color.main, fontWeight: 600 }}>{title}</AlertTitle>
          <Typography variant='body1' color='text.secondary'>
            {explanation}
          </Typography>
        </Alert>
        )
      : null
  }
}

Explanations.propTypes = propTypes
Explanations.defaultProps = defaultProps

export default Explanations
