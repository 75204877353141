import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Skeleton, Stack, useMediaQuery, useTheme, Tooltip, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PoiIcon } from '../icons/PoiIconBold.svg'
import { ReactComponent as SiloIcon } from '../icons/SiloIconBold.svg'
import { ContentIcon, LinkIcon } from '../icons/index'
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  device_name: PropTypes.string,
  capa_max: PropTypes.number,
  group_poi: PropTypes.shape({
    group_poi_name: PropTypes.string,
    group_poi_id: PropTypes.string
  }),
  deviceContent: PropTypes.string,
  is_combined: PropTypes.bool,
  deviceDetail: PropTypes.bool,
  has_parent_combined_device: PropTypes.bool
}

const defaultProps = {
  loading: false,
  capa_max: null,
  group_poi: { group_poi_name: '???' },
  deviceContent: '',
  device_name: '',
  is_combined: false,
  deviceDetail: false,
  has_parent_combined_device: false
}

/**
 *
 */
function DeviceTitle ({ loading, device_name: deviceName, capa_max: capaMax, group_poi: groupPoi, deviceContent, is_combined: isCombined, has_parent_combined_device: childrenCombined, deviceDetail }) {
  const { t } = useTranslation()
  const variant = deviceDetail ? 'body1' : 'body2'
  const notApplicable = t('not_applicable')
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))

  const [overflowDeviceContent, setOverflowDeviceContent] = useState(false)
  const [overflowGroupName, setOverflowGroupName] = useState(false)
  const [overflowDeviceName, setOverflowDeviceName] = useState(false)
  const deviceContentRef = useRef()
  const groupNameRef = useRef()
  const deviceNameRef = useRef()
  const isOverflowActive = (event) => {
    return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth
  }

  useEffect(() => {
    if (!mobileFormat) {
      if (isOverflowActive(deviceContentRef.current)) {
        setOverflowDeviceContent(true)
      } else setOverflowDeviceContent(false)
      if (!childrenCombined) {
        if (isOverflowActive(groupNameRef.current)) {
          setOverflowGroupName(true)
        } else setOverflowGroupName(false)
      }
      if (!deviceDetail) {
        if (isOverflowActive(deviceNameRef.current)) {
          setOverflowDeviceName(true)
        } else setOverflowDeviceName(false)
      }
    }
  }, [isOverflowActive])
  return (
    <Grid container spacing={0} direction='column'>
      {!deviceDetail && (
        <Grid item container alignContent='flex-start' alignItems='center'>
          <Grid item xs={10}>
            <Stack direction='row' spacing={0.5} alignItems='stretch'>
              <Tooltip title={overflowDeviceName && deviceName}>
                <Typography ref={deviceNameRef} noWrap variant='body1'>
                  {loading ? <Skeleton width={80} /> : (deviceName)}
                </Typography>
              </Tooltip>
              {isCombined && <LinkIcon />}
            </Stack>
          </Grid>
        </Grid>)}
      {(!mobileFormat || deviceDetail) &&
        <>
          {!childrenCombined &&
            (
              <Grid item container spacing={1}>
                <Grid item alignItems='center' display='flex'>
                  <PoiIcon />
                </Grid>
                <Grid item xs={8}>
                  <Tooltip title={overflowGroupName && groupPoi.group_poi_name}>
                    <Typography ref={groupNameRef} noWrap variant={variant} color='text.secondary'>
                      {loading ? <Skeleton /> : groupPoi.group_poi_name}
                    </Typography>
                  </Tooltip>

                </Grid>
              </Grid>
            )}
          <Grid item container spacing={1}>
            <Grid item alignItems='center' display='flex'>
              <SiloIcon />
            </Grid>
            <Grid item xs={8}>
              <Typography noWrap variant={variant} color='text.secondary'>
                {loading ? <Skeleton /> : t('device_caracteristic_capacity_max_with_count', { count: t('number_workspace_filling_unit', { value: capaMax }) })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item alignItems='center' display='flex'>
              <ContentIcon />
            </Grid>
            <Grid item xs={!deviceDetail ? 8 : 10}>
              <Tooltip title={overflowDeviceContent && deviceContent}>
                <Typography ref={deviceContentRef} noWrap={!deviceDetail} variant={variant} color='text.secondary'>
                  {loading ? <Skeleton /> : deviceContent || notApplicable}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>}

    </Grid>
  )
}

DeviceTitle.propTypes = propTypes
DeviceTitle.defaultProps = defaultProps
// #endregion

export default DeviceTitle
