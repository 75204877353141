import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Stack, Typography } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { client } from '../../shared/apiClient'
import Forecasts from './components/Forecasts'
import NanoSection from '../../shared/components/NanoSection'
import Historic from './components/Historic'
import DeviceCharacteristics from './components/DeviceCharacteristics'
import TopPage from '../layout/TopPage'
import { useWorkspaceSettingsStore } from '../../shared/store'
import DeviceModal from '../dashboard/DeviceModal'
import { ToastContext } from '../../shared/contexts'
import Explanations from './components/Explanations'
import DeviceFirstSection from './components/DeviceFirstSection'
import DeviceList from './components/DeviceList'
import { LinkIcon } from '../../shared/icons/index'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
function Device () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toastContext = useContext(ToastContext)
  const { id } = useParams()
  /** @type {[import('../../../openapiDoc').components['schemas']['dashboardV2'][0], React.Dispatch<import('../../../openapiDoc').components['schemas']['dashboardV2'][0]>]} */
  const [device, setDevice] = useState({})
  const disableOrders = useWorkspaceSettingsStore((state) => state.getSetting('disableOrders'))?.value === 'true'
  const [dataLoading, setDataLoading] = useState(false)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const devicesCombined = device.devices_combined?.map(device => ({
    ...device,
    has_parent_combined_device: true
  }))
  const getData = async () => {
    setDataLoading(true)
    try {
      const response = await client.GET('/v2/dashboard/{device_id}', { params: { path: { device_id: id } } })
      if (response.data.length === 0) {
        navigate('/not_found')
      } else {
        setDevice(response.data[0])
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setDataLoading(false)
    }
  }

  useEffect(() => { window.scrollTo(0, 0); getData() }, [id])

  const handleDeviceUpdated = () => {
    toastContext.sendMessage(t('device_snackbar_alert_updated'))
    getData()
  }

  return (
    <>
      <TopPage
        back
        renderTitle={(
          <Stack display='flex' justifyContent='center' spacing={1} direction='row' alignItems='center'>
            <Typography variant='h1' color='black'>{device.device_name}</Typography>
            {(device.is_combined && <LinkIcon height={24} width={24} />)}
          </Stack>
        )}
      />
      <Grid container direction='column' sx={{ pb: 30 }}>
        <NanoSection pt={0}>
          <DeviceFirstSection device={device} loading={dataLoading} handleEdit={() => setFormIsOpen(true)} displayOrder={!disableOrders} />
        </NanoSection>
        <NanoSection>
          <Explanations data={device} loading={dataLoading} />
        </NanoSection>
        {device.is_combined &&
          (
            <NanoSection title={t('combined_bins_title')}>
              <DeviceList data={devicesCombined} loading={dataLoading} nanoPaperProps={{ sx: { pt: 0, pb: 0 } }} />
            </NanoSection>
          )}
        {device.status === 'ok' &&
          <NanoSection title={device.has_parent_combined_device ? t('level') : t('device_forecasts_title')}>
            <Forecasts data={[device]} loading={dataLoading} />
          </NanoSection>}
        <NanoSection title={t('device_info')}>
          <DeviceCharacteristics data={device} loading={dataLoading} />
        </NanoSection>
        <NanoSection title={t('device_historic_title')}>
          <Historic
            loading={dataLoading}
            deviceRef={device.device_reference}
            deviceName={device.device_name}
            deviceCorrectionType={device.configuration?.correction_type}
            startDisplayDate={device.start_display_date}
            installDate={device.device_install_date}
            capaMax={device.capa_max}
            devicesCombined={devicesCombined}
          />

        </NanoSection>
      </Grid>

      {formIsOpen &&
        (
          <DeviceModal
            isOpen={formIsOpen}
            onClose={() => setFormIsOpen(false)}
            onSuccess={handleDeviceUpdated}
            device={device}

          />
        )}
    </>
  )
}

Device.defaultProps = defaultProps
Device.propTypes = propTypes
// #endregion

export default Device
