import React, { useEffect, useState, useCallback, useContext, useRef } from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Skeleton, TableCell, useTheme, useMediaQuery, IconButton, Tooltip, Stack, Typography } from '@mui/material'
import { ToastContext } from '../../shared/contexts'
import { client, patchRequest } from '../../shared/apiClient'
import FilterBar from '../../shared/components/FilterBar'
import NanoTable from '../../shared/components/NanoTable'
import SimpleCell from '../../shared/components/SimpleCell'
import TopPage from '../layout/TopPage'
import { debounce } from '@mui/material/utils'
import MoreActionUsers from './MoreActionUsers'
import ButtonAdd from '../../shared/components/ButtonAdd'
import UserModal from './UserModal'
import NanoSelectSingle from '../../shared/components/NanoSelectSingle'
import NanoSelectMultiple from '../../shared/components/NanoSelectMultiple'
import UserGroupCell from './UserGroupCell'
import UserAvatarAndName from '../../shared/components/UserAvatarAndName'
import { useGroupStore, useSessionStore } from '../../shared/store'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import { MessageSentIcon } from '../../shared/icons/index'
import { dateShortWithoutTime } from '../../shared/utils/dateUtils'

// #region functions
const onRoleChange = (newRole, idUser) => patchRequest(`v1/users/${idUser}/`, { idRole: newRole })
// #endregion
// #region constants

/**
 * Build the columns with a callback function to send to children component in case they need to refresh the list
 * @param {function} fetchUsers Function to fetch all users
 * @returns array of columnss
 */
const columns = (fetchUsers, t, mobileFormat, handleEdit, optionsRole, me, sendReinvite, searchedUser) => [
  {
    header: t('menu_users'),
    width: 450,
    format: (row) => (
      <TableCell>
        <UserAvatarAndName {...row} withEmail={!mobileFormat} withPhone />
      </TableCell>
    )
  },
  {
    header: t('role'),
    width: 180,
    hide: 'sm',
    format: (row, t) => (
      row.loading
        ? <Skeleton width='80%' />
        : (
          <AccessRoleComponent
            resource='User'
            operation={['CREATE', 'UPDATE']}
            fallbackComponent={(
              <SimpleCell
                data={t(optionsRole?.find((opt) => opt.value === row.workspace_role.idRole)?.name)}
                {...row}
              />
          )}
          >
            <TableCell>
              <NanoSelectSingle
                width={90}
                backgroundColor='transparent'
                options={optionsRole}
                onChange={(event) => onRoleChange(event.target.value, row.id).then(() => fetchUsers(searchedUser))}
                value={row.workspace_role.idRole}
                disabled={me.idUser === row.idUser}
              />
            </TableCell>
          </AccessRoleComponent>
          )
    )
  },
  {
    header: t('last_login'),
    hide: 'lg',
    format: (row, t) => (
      <SimpleCell
        data={
            row.last_login
              ? dateShortWithoutTime(row.last_login)
              : (
                <Stack direction='row' display='flex' alignItems='center' spacing={1}>
                  <Typography variant='body1'>
                    {t('no_login_yet')}
                  </Typography>
                  <Tooltip title={t('resend_invitation')}>
                    <IconButton
                      sx={{ backgroundColor: 'grey.main', width: 40, height: 40 }}
                      onClick={() => sendReinvite(row)}
                    >
                      <MessageSentIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                )
  }
        {...row}
      />
    ),
    width: 450
  },
  {
    header: t('access'),
    width: 500,
    hide: 'sm',
    format: (row) => (
      row.loading
        ? <Skeleton width='80%' />
        : (
          <UserGroupCell {...row} />
          ))
  },
  {
    header: 'actions',
    format: (row) => (<MoreActionUsers fetchUsers={fetchUsers} handleEdit={() => handleEdit(row)} {...row} />),
    width: 50
  }
]

// #endregion

// #region styled-components

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
function Users () {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [filterRole, setFilterRole] = useState([])
  const [searchedUser, setSearchedUser] = useState('')
  const searchedUserRef = useRef('')
  const [defaultValues, setDefaultValues] = useState({})
  const toastContext = useContext(ToastContext)
  const theme = useTheme()
  const me = useSessionStore((state) => state.user)
  const [optionsRole, setOptionsRole] = useState([])
  const optionsRoleEnabled = optionsRole?.filter((role) => role?.hierarchy >= me.workspace_role.hierarchy)
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))

  // Let search query read the current search value after fetching
  useEffect(() => {
    searchedUserRef.current = searchedUser
  }, [searchedUser])

  const fetchUsers = useCallback(async (searchedUser = '') => {
    setDataLoading(true)
    try {
      const userlists = await client.GET('/v1/users', { params: { query: { search: searchedUser } } })
      if (searchedUserRef.current !== searchedUser) return // discard if search changed

      setData(userlists.data.results)
    } finally {
      setDataLoading(false)
    }
  }, [])

  const getOptionsDelayed = useCallback(
    debounce(fetchUsers, 500),
    []
  )

  useEffect(() => {
    getOptionsDelayed(searchedUser)
  }, [searchedUser])

  useEffect(() => {
    // Fetch roles
    client.GET('/v2/role', {})
      .then((result) => {
        const optionsRole = [
          ...(result.data?.map((r) => ({ name: r.display_name, value: r.idRole, hierarchy: r.hierarchy, global: r.global })) || [])
        ]
        setOptionsRole(optionsRole)
      })
    useGroupStore.getState().fetchData()
  }, [])
  const handleUserDidCreate = (update) => {
    toastContext.sendMessage(update ? t('users_snackbar_user_updated') : t('users_snackbar_user_invited'))
    fetchUsers(searchedUser)
  }

  const handleEdit = (row) => {
    setFormIsOpen(true)
    setDefaultValues(row)
  }

  const sendReinvite = (row) => {
    client.GET('/v1/users/{id}/reinvite', { params: { path: { id: row.id } } }).then(() =>
      toastContext.sendMessage(t('users_snackbar_user_reinvited')))
  }
  const rows = !filterRole.length
    ? data
    : data.filter((d) => filterRole.includes(d.workspace_role.idRole))

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%' }}>
      <TopPage
        title={!mobileFormat ? t('menu_users') : null}
        actions={<ButtonAdd text={t('users_button_title')} onClick={() => { setDefaultValues({}); setFormIsOpen(true) }} />}
      />
      <FilterBar
        isSearch
        searchFieldPlaceholder='search'
        searchFieldAction={setSearchedUser}
        searchFieldValue={searchedUser}
      >
        <NanoSelectMultiple
          placeholder={t('role')}
          options={optionsRole}
          value={filterRole}
          handleSelect={setFilterRole}
        />
      </FilterBar>
      <NanoTable columns={columns(fetchUsers, t, mobileFormat, handleEdit, optionsRoleEnabled, me, sendReinvite, searchedUser)} data={rows} loading={dataLoading} />
      {formIsOpen &&
        <UserModal
          isOpen={formIsOpen}
          onClose={() => { setFormIsOpen(false) }}
          onSuccess={handleUserDidCreate}
          optionsRole={optionsRoleEnabled}
          defaultValues={defaultValues}
        />}
    </div>
  )
}

Users.propTypes = propTypes
Users.defaultProps = defaultProps
// #endregion

export default Users
